import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { ClientInterceptor } from './shared/interceptors/client-interceptor';
import { ForbidenComponent } from './auth-guard/forbiden.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { BlockUIModule } from 'ng-block-ui';
import { UserService } from './services/user.service';
import { TranslateSharedModule } from './shared/translate/translate.module';
import { SuporteModule } from './suporte/suporte.module';
import { DashboardComponent } from './dashboard.component';
import { FeedComponent } from './feed/feed.component';
import { CertificadoComponent } from './certificado/certificado.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    RouterModule.forRoot(AppRoutes),
    BlockUIModule.forRoot(),
    TranslateSharedModule,
    SharedModule,
  ],
  declarations: [
    DashboardComponent,
    AppComponent,
    ForbidenComponent,
    FeedComponent,
  ],
  exports: [
    TranslateSharedModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
